import { Helmet } from "react-helmet";
import BookingForm from "../components/BookingForm";
import venice from '../imgs/venice.webp';

export default function Trip() {
	return (
		<>
			<Helmet>
				<title>Trip Details - Visit React template</title>
			</Helmet>

			<section className="pb-6">
				<img src={venice} className="headtrip" alt="Venice Trip - Visit React template" />

				<div className="container grid gtc-2-1 g-2 align-items-start pt-6">
					<div className="pb-2">
						<h2 className="pb-2">Trip Details</h2>
						<h3>Departure</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						<h3>Destinations</h3>
						<p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<h3>What we offer</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing exercitation ullamco laboris nisi ut aliquip.</p>
						<h3>Services</h3>
						<ul className="list">
							<li>Lorem ipsum</li>
							<li>Dolor sit amet</li>
							<li>Amet consectetur</li>
							<li>Adipiscing elit</li>
						</ul>
						<h3>Pricing</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
					</div>

					<BookingForm />
				</div>
			</section>
		</>
	);
}