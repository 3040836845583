import { useState, useEffect, useCallback } from 'react';

export default function useSlider(slides) {
   const [current, setCurrent] = useState(0),
         length = slides.length,
         time = 4000;

   const handleNext = useCallback(() => {
      current + 1 >= length ?
         setCurrent(0) :
         setCurrent(current + 1);
   }, [current, length]);
   
   const handlePrev = () => {
      current === 0 ?
         setCurrent(length - 1) :
         setCurrent(current - 1);
   };

   useEffect(() => {
      const autoplay = setInterval(handleNext, time);
      return () => clearInterval(autoplay);
   }, [handleNext]);

   return [current, handlePrev, handleNext];
}