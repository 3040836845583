import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// component
import FollowUs from './FollowUs';
// image
import logo from '../imgs/logo.svg';


export default function Footer() {
   const dateYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >

         <div className="grid gtc-4 g-3 align-items-start">
            <div>
               <h3>About</h3>
               <p>The perfect Tour & Travel Booking React template!</p>
               <img src={logo} className="pb-2" alt="Visit React template logo" />
            </div>

            <ul className="w-100">
               <li><h3>Contacts</h3></li>
               <li><address>Youe adrress here - 36000 - Venice, Italy</address></li>
               <li>Phone: <a href="mailto:321123456">+39 321 123456</a></li>
               <li>Email: <a href="mailto:your@email.com">your@email.com</a></li>
               <li><a href="https://www.templatemonster.com/website-templates/visit-the-perfect-tour-amp-travel-booking-react-website-template-312399.html?utm_campaign=visit_react&utm_source=theme&utm_medium=referral&aff=travolgi" className="more">See on maps +</a></li>
            </ul>

            <ul className="w-100">
               <li><h3>Page Links</h3></li>
               <li><Link to="/">HOME</Link></li>
               <li><Link to="trip">TRIP</Link></li>
               <li><Link to="pricing">PRICING PLANS</Link></li>
               <li><Link to="contact">CONTACT US</Link></li>
               <li><Link to="booking">BOOKING</Link></li>
            </ul>

            <FollowUs />
         </div>
         
         <p className="txt-center pt-6 pb-2">&copy; Visit React template {dateYear}. Developed by <Link to="/">Travolgi</Link>.</p>
      </motion.footer>
   );
}