import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
   return (
      <section className="container txt-center pt-6 pb-6">
         <Helmet>
            <title>Page Not Found - Visit React template</title>
         </Helmet>
         
         <h1 className="pb-2">404</h1>
         <h2 className="pb-2">Page Not Found</h2>

         <button className="fw-600" style={{ background: 'var(--bg2)', padding: '.8rem' }}>
            <Link to="/" className="btn">Back to home</Link>
         </button>
      </section>
   );
}