import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
// component
import PricingTable from '../components/PricingTable';

// data list of trips 
const tripsList = [
   {
      name: 'Sunset dinner',
      price: 99,
      list: [
         'Build on latest React',
         'High Performance',
         'Flexbox & Grid layout',
         'SEO Friendly',
         'Google Fonts'
      ]
   },
   {
      name: 'Half day trip',
      price: 375,
      list: [
         'Build on latest React',
         'High Performance',
         'Flexbox & Grid layout',
         'SEO Friendly',
         'Google Fonts'
      ]
   },
   {
      name: 'Day trip',
      price: 549,
      list: [
         'Build on latest React',
         'High Performance',
         'Flexbox & Grid layout',
         'SEO Friendly',
         'Google Fonts'
      ]
   },
   {
      name: 'Weekend trip',
      price: 997,
      list: [
         'Build on latest React',
         'High Performance',
         'Flexbox & Grid layout',
         'SEO Friendly',
         'Google Fonts'
      ]
   }
];


export default function Pricing() {
	return (
      <>
         <Helmet>
            <title>Pricing Plans - Visit React template</title>
         </Helmet>

         <motion.section
            className="container pt-6 pb-6"

            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .8, ease: 'easeInOut' }}
            exit={{ opacity: 0, y: '100%' }}
         >
            <h4>Choose the best trip</h4>
            <h1>Pricing Plans</h1>

            <p className="pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam nostrud exerci.</p>

            <div className="grid g-2 align-items-start">
               {tripsList.map((trip, idx) =>
                  <PricingTable
                     key={idx}
                     title={trip.name}
                     price={trip.price}
                     list={trip.list}
                  />
               )}
            </div>
         </motion.section>
      </>
	);
}