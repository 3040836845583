import { motion } from 'framer-motion';
// hook component
import useSlider from '../hooks/useSlider';
// component
import SliderBtns from './SliderBtns';


export default function HeroSlider({ slides, children }) {
   const [current, handlePrev, handleNext] = useSlider(slides);

   return (
      <motion.section
         className="slider flex space-around g-4"
         style={{ backgroundImage: `url(${slides[current]})` }}

         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .5, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
      >

         {children}

         <SliderBtns
            handlePrev={handlePrev}
            handleNext={handleNext}
         />
      </motion.section>
   );
}