import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
// component
import ReservationForm from '../components/BookingForm';
// image
import booking from '../imgs/booking.webp';


export default function Booking() {
   return (
      <motion.section
         className="container pt-6 pb-6"
         
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: .7, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <Helmet>
            <title>Booking - Visit React template</title>
         </Helmet>
         
         <h4>Ready to go?</h4>
         <h1 className="mb-3">Booking now</h1>

         <div className="grid gtc-1-2 g-3 mb-3">
            <img src={booking} className="self-start" alt="Booking now - Visit React template" />

            <div className="w-100">
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

               <ReservationForm />
            </div>

         </div>
      </motion.section>
   );
}