import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// components
import HeroSlider from '../components/HeroSlider';
import BookingForm from '../components/BookingForm';
import ImgCarousel from '../components/ImgCarousel';
// images
import travel from '../imgs/travel.webp';
import gondole from '../imgs/gondole.webp';
import carnival from '../imgs/carnival.webp';
import skyline from '../imgs/skyline.webp';
import maps from '../imgs/maps.png';
import mask from '../imgs/mask.webp';
import riverChannel from '../imgs/river-channel.webp';
import gondola from '../imgs/gondola.webp';
import boat from '../imgs/boat.webp';
import ferry from '../imgs/ferry.webp';
import carnivalMask from '../imgs/carnival-mask.webp';
import piazza from '../imgs/piazza.webp';
import venice from '../imgs/venice.webp';


export default function Home() {
	return (
      <>
         <Helmet>
            <title>Visit: the perfect Tour & Travel Booking React Website template</title>
            <meta  name="description" content="Make booking easy and take your customers on trips around the world with an attractive design with our Tour and Travel Booking React Website template for your Travel agency" />
         </Helmet>

         <HeroSlider slides={[ travel, gondole, carnival, skyline ]}>
            <motion.div
               className="bg-blur"

               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .7, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
               >
                  <h1 className="txt-white">Visit React template</h1>
                  <h2>The perfect design for your travel agency</h2>
                  <p>Do you dream of creating a travel website that inspires users to explore the world?</p>
                  <p>Then look no further: make booking easy and take your customers on trips around the world with an attractive design.</p>
               </motion.div>
            <BookingForm />
         </HeroSlider>


         <section className="pt-6 pb-6">
            <div className="container grid align-items-start gtc-1-2 g-2">
               <div className="fs-md txt-right">
                  <p>Are you looking for a professional design for your travel website? Visit React template, with a minimal and attractive layout, offers a unique user experience for your visitors.</p>
                  
                  <img src={maps} className="mbk-2" alt="Venice Maps - Visit React template" />
                  
                  <p className="f-serif fs-md txt-primary">Visit template is fully optimized for search engines and compatible with all devices. Browse our exclusive travel template!</p>
               </div>

               <div>
                  <p><span className="dropcap">M</span><small>ake booking easy and take your customers on trips around the world with an attractive design with our Tour and Travel Booking React Website template for your Travel agency. </small></p>
                  
                  <p>Our template has been designed with a minimal and elegant design, perfect for any type of travel site, from travel agencies to tour organizers: your website will be unique and original.</p>
                  
                  <div className="grid g-2 mbk-3">
                     <img src={mask} alt="Venice carnival - Visit React template" />
                     <img src={riverChannel} alt="River Channel Venice - Visit React template" />
                  </div>

                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.</p>
               </div>
            </div>
         </section>

         <section className="grid gtc-2-1 g-2 pb-2">
            <ImgCarousel
               slides={[ gondola, boat, ferry ]}
               btnSide="right"
            />

            <div className="box-light">
               <h3>Boat trip</h3>
               <h2>Choose the boat trip you prefer</h2>
               <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
               <Link to="trip" className="more">Discover +</Link>
            </div>
         </section>

         <section className="grid gtc-1-2 g-2 pb-6">
            <div className="box-light">
               <h3>Don't miss the best</h3>
               <h2>See the best places</h2>
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
               <Link to="trip" className="more">Discover +</Link>
            </div>

            <ImgCarousel
               slides={[ carnivalMask, piazza, venice ]}
               btnSide="left"
            />
         </section>

         <section className="container pb-6">
            <h1 className="txt-center">Book your trip now!</h1>
            <h2 className="txt-center">Enjoy traveling</h2>
            
            <BookingForm />
         </section>
      </>
	);
}
