import { Link } from 'react-router-dom';


export default function PricingTable({ title, price, list }) {
   return (
      <article className="pricing">
         <h2>{title}</h2>
         {price &&
            <h3>$ {price} <small>per person</small></h3>
         }
         <ul>
            {list.map((li, idx) =>
               <li key={idx}>{li}</li>
            )}
         </ul>

         <Link to="/booking" className="btn">Booking Now</Link>
      </article>
   );
}