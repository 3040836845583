import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import  emailjs from '@emailjs/browser';


export default function ContactForm() {
   const form = useRef();
   const initValue = { name:'', email: '', tel: '', message:'' };
   const [formVals, setFormVals] = useState(initValue),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);

   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };
   
   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals));
      setIsSubmit(true);
   }

   // send email with emailjs service
   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_ak7hy2c', 'template_luud22b', form.current, 'cIpXspQX-aee8phaF')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);
   
   // validation of form fields
   const validate = (vals) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            phoneRegex= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      
      if (!vals.name) {
         errors.name = 'The name is required!';
      } else if (vals.name.length < 4) {
         errors.name = 'Enter at least 4 characters!';
      }

      if (!vals.email) {
         errors.email = 'The email is required!';
      } else if (!emailRegex.test(vals.email)) {
         errors.email = 'This is not a valid email format!';
      }

      if (!vals.tel) {
         errors.tel = 'The telephone is required!';
      } else if (!phoneRegex.test(vals.tel)) {
         errors.tel = 'This is not a valid format!';
      }
      
      return errors;
   };

   return (
      <motion.form
         ref={form}
         onSubmit={handleSubmit}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <input
            id="name"
            name="name"
            type="text"
            placeholder="Full Name"
            required
            value={formVals.name}
            onChange={handleChange}
         />
         {formErrs.name &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.name}</h4>
         }

         <input
            id="email"
            name="email"
            type="email"
            placeholder="Your Email"
            required
            value={formVals.email}
            onChange={handleChange}
         />
         {formErrs.email &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.email}</h4>
         }

         <input
            id="tel"
            name="tel"
            type="tel"
            placeholder="Your Phone Number"
            required
            value={formVals.tel}
            onChange={handleChange}
         />
         {formErrs.tel &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.tel}</h4>
         }

         <textarea
            id="message"
            name="message"
            rows="6"
            placeholder="Your Message"
            value={formVals.message}
            onChange={handleChange}
         />

         {isSent &&
            <motion.h3
               className="txt-center txt-success"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >Message sent!</motion.h3>
         }

         {Object.keys(formErrs).length !== 0 &&
            <h3
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >Error! Check the fields.</h3>
         }

         <input type="submit" value="Send Now"/>
      </motion.form>
   );
}