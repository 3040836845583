import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
// component
import Weather from './Weather';
// image
import logo from '../imgs/logo.svg';


export default function Header() {
   const refNavBar = useRef(),
         [isOpen, setIsOpen] = useState(false),
         handleToggle = () => setIsOpen(!isOpen);    

   return (
      <motion.header
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .5, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
      >
         <Link to="/" className="w-100">
            <img src={logo} className="logo" alt="Visit React template logo" />
         </Link>
         
         <button
				className="nav-toggle"
				aria-controls={refNavBar}
				aria-expanded={isOpen}
				onClick={handleToggle}
         >
            <span className="sr-only">Menu</span>
         </button>
         <nav
            id="navbar"
            ref={refNavBar}
            data-visible={isOpen}
            onFocus={handleToggle}
         >
            <ul className="flex g-2">
               <li><Link to="/">Home</Link></li>
               <li><Link to="trip">Trip</Link></li>
               <li><Link to="pricing">Pricing Plans</Link></li>
               <li><Link to="contact">Contact</Link></li>
               <li><Link to="booking" className="btn txt-white">Book now!</Link></li>
            </ul>
         </nav>
         
         <Weather />
      </motion.header>
   );
}