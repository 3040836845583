export default function WhatsAppBtn() {
   return (
      <a
         id="wachat"
         href="https://www.templatemonster.com/website-templates/visit-the-perfect-tour-amp-travel-booking-react-website-template-312399.html?utm_campaign=visit_react&utm_source=theme&utm_medium=referral&aff=travolgi"
         rel="noreferrer"
      >
         <p className="wamessage">Hi, how can we help you?</p>
      </a>
   );
}