import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
// component
import ContactForm from '../components/ContactForm';
// image
import river from '../imgs/river.webp';


export default function Contact() {
   return (
      <motion.section
         className="container pt-6 pb-6"
         
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: .7, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <Helmet>
            <title>Contact us - Visit React template</title>
         </Helmet>
         
         <div className="grid gtc-2-1 g-3 mb-3">
            <div className="mb-3">
               <h4>Do you have questions?</h4>
               <h1>Contact us!</h1>
               <p className="mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam nostrud exerci.</p>

               <ContactForm />
            </div>

            <img src={river} className="self-start" alt="Visit React template" />
         </div>

         <iframe title="Maps" src="https://www.google.com/maps?q=Venice&t=&z=10&ie=UTF8&iwloc=&output=embed" />
      </motion.section>
   );
}