// hook component
import useFetch from '../hooks/useFetch';


export default  function Weather() {
   // API link
   const URL_WEATHER = `https://api.openweathermap.org/data/2.5/weather?q=Venice,it&units=metric&appid=1ea0775b28d1141fd1c718a7303eb576`;

   // get data from API
   const [data, error, isLoading] = useFetch(URL_WEATHER);

   return (
      <time className="flex">
         {isLoading ?
            <p>loading...</p>
         : error || data.status === 404 ?
            <p>Ops! Error.</p>
         :
            <>
               <p>{data.name}</p>
               <img src={`https://openweathermap.org/img/wn/${data.weather && data.weather[0].icon}@2x.png`} alt={data.name+' meteo'} />
               <p>{parseInt(data.main && data.main.temp)} °C</p>
            </>
         }
      </time>
   );
}