import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// loading spinner component
import SyncLoader from 'react-spinners/SyncLoader';
// components
import Header from './components/Header';
import Footer from './components/Footer';
import WhatsAppBtn from './components/WhatsAppBtn';
import ScrollToTop from './components/ScrollToTop';
import BuyNowBtn from './components/BuyNowBtn';
// design pages component
import Home from './pages/Home';
import Trip from './pages/Trip';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
import ErrorPage from './pages/ErrorPage';


function App() {
	const location = useLocation();
	// on change page scrool top 
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location]);

	// loading effect
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const load = setTimeout(()=>setIsLoading(false), 1200);
		return () => clearTimeout(load);
	}, [])
	
	// loading design
	if(isLoading) return(
		<motion.div
			className="loading"
			initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .2, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
		>
			<SyncLoader color="#169fa9" size={20} />
		</motion.div>
	);

	// design page
	return (
		<AnimatePresence>
			<Header />
			
			<main>
				<Routes location={location} key={location.key}>
					<Route path="/" element={<Home />} />
					<Route path="trip" element={<Trip />} />
					<Route path="pricing" element={<Pricing />} />
					<Route path="contact" element={<Contact />} />
					<Route path="booking" element={<Booking />} />
					<Route path='/*' element={<ErrorPage />} />
				</Routes>
			</main>

			<Footer />

			<WhatsAppBtn />
			<ScrollToTop />
			<BuyNowBtn />
		</AnimatePresence>
	);
}

export default App;
